import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'
import { notification } from 'antd'
import axios from 'axios'
import React from 'react'

import { useAuthStore } from '../../state-stores/auth.store'

export const GoogleLoginButton: React.FC = () => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID

  const [api, contextHolder] = notification.useNotification()

  const loginError = (description) => {
    api.error({
      message: 'Login failed',
      description,
      duration: 10,
    })
  }

  function onSuccess(data) {
    const { credential } = data
    if (credential) {
      callAPI(credential)
    }
  }

  function onFailure(error) {
    console.log('Login Google error', error)
    loginError(error.message || 'Login Google error!')
  }

  const { setToken, setUserData, clearToken } = useAuthStore((state) => ({
    setToken: state.setToken,
    clearToken: state.clearToken,
    setUserData: state.setUserData,
  }))

  function callAPI(token) {
    axios
      .post('/authentication/verification', { provider: 'GOOGLE', token })
      .then(({ data: { token, error, data } }) => {
        if (token) {
          setToken(token)
          setUserData(data)
        } else {
          loginError(error || 'Login failed. Please try again!')
        }
      })
      .catch((err) => {
        clearToken()
        loginError(err.message || JSON.stringify(err))
        console.error(err)
      })
  }

  return (
    <>
      {contextHolder}
      <GoogleOAuthProvider clientId={clientId}>
        <span className="flex justify-center">
          <GoogleLogin
            cookiePolicy="single-host-origin"
            theme="filled_blue"
            text="continue_with"
            ux_mode="popup"
            size="large"
            onSuccess={onSuccess}
            onError={onFailure}
          />
        </span>
      </GoogleOAuthProvider>
    </>
  )
}
