import { create } from 'zustand'

const initialState = {
  results: {},
  voteResult: {},
  timerInfo: {},
  roomInfo: {},
  hasRoomInfo: false,
  isOpenQR: false,
  isEditing: false,
}

export const useRoomStore = create((set, get) => ({
  ...initialState,
  setTimerInfo: (timerInfo) => set(() => ({ timerInfo })),
  setResults: (results) => set(() => ({ results })),
  setVoteResult: (voteResult) => set(() => ({ voteResult })),
  setIsOpenQR: (isOpenQR: boolean) => set(() => ({ isOpenQR })),
  setIsEditing: (isEditing) => set(() => ({ isEditing })),
  setRoomInfo: (roomInfo) => set(() => ({ roomInfo, hasRoomInfo: true })),
  getRoomInfo: () => get().roomInfo,
  clearRoomInfo: () => set(() => ({ roomInfo: {}, hasRoomInfo: false })),
  isRoomOwner: (userId) => userId === get().roomInfo['owner'],
  hasVoted: (userId) => {
    const results = get().results['estimators'] || []
    return !!results.find(({ id, isVoted }) => id === userId && isVoted)
  },
  isWatcher: (userId) => {
    const watchers = get().results['watchers'] || []
    return !!watchers.find(({ id }) => id === userId)
  },
}))
