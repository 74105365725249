import {
  CheckCircleOutlined,
  CrownOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Avatar, Badge, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import React from 'react'

import { useRoomStore } from '../../state-stores/room.store'

import { VOTING_TEXTS } from './vote-values.const'

interface DataType {
  email: string;
  name: string;
  point: number;
}

const columns: ColumnsType<DataType> = [
  {
    dataIndex: 'avatar',
    render: (src, { isOnline }) => (
      <Badge status={isOnline ? 'success' : 'error'} dot>
        <Avatar shape="square" size={32} src={src} />
      </Badge>
    ),
    width: 50,
  },
  {
    dataIndex: 'name',
    title: 'Name',
    render: (value, { isOwner, isVoted, isShowing }) => (
      <span className={isShowing && !isVoted ? 'text-red-700 underline' : ''}>
        {value}
        {isOwner ? <CrownOutlined className="text-yellow-50 ml-1" /> : ''}
      </span>
    ),
  },
  {
    dataIndex: 'point',
    title: 'Point',
    align: 'right',
    render: (value, { isVoted, isShowing, isHighest }) => {
      if (isVoted) {
        const pointValue = isShowing ? (
          VOTING_TEXTS[value] || value
        ) : (
          <CheckCircleOutlined />
        )

        return (
          <Tag
            className="text-base font-semibold w-8 text-center"
            color={isVoted ? (isHighest ? '#e52b50' : '#6042EC') : ''}
          >
            {pointValue}
          </Tag>
        )
      }

      return (
        <Tag className="text-base font-semibold text-zinc-500 text-center">
          <QuestionCircleOutlined />
        </Tag>
      )
    },
  },
]

export const ListResultsComponent: React.FC = () => {
  const { results, isRoomOwner } = useRoomStore((state) => ({
    results: state.results,
    isRoomOwner: state.isRoomOwner,
  }))

  const {
    estimators = [],
    isShowing,
    statistic: { averagePoint = 0, highestPoint = 0 } = {},
  } = results || {}

  const dataSource = estimators.map((data) => ({
    isShowing,
    key: data.email,
    name: data.name,
    avatar: data.avatar,
    point: data.point,
    isOwner: isRoomOwner(data.id),
    isVoted: data.isVoted,
    isOnline: data.isOnline,
    isHighest:
      isShowing && highestPoint > averagePoint && data.point === highestPoint,
  }))

  const sortedData = isShowing
    ? dataSource
        .sort((a, b) => b.isVoted - a.isVoted)
        .sort((a, b) => b.point - a.point)
    : dataSource.sort((a, b) => {
        if (a.name === b.name) {
          return 0
        }
        return a.name > b.name ? 1 : -1
      })

  return <Table pagination={false} columns={columns} dataSource={sortedData} />
}
