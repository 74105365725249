import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  PoweroffOutlined,
} from '@ant-design/icons'
import { Button, Input, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'

import { useAuthStore } from '../../../state-stores/auth.store'
import { useRoomStore } from '../../../state-stores/room.store'
import { socket } from '../../../state-stores/websocket.store'

const timerValues = [20, 30, 40, 60, 90]

export const ResultTimerActionsComponent: React.FC = () => {
  const [duration, setDuration] = useState(timerValues[0])
  const [isPlaying, setIsPlaying] = useState(false)

  const {
    roomInfo,
    isRoomOwner,
    timerInfo: { isRunning },
  } = useRoomStore((state) => ({
    roomInfo: state.roomInfo,
    isRoomOwner: state.isRoomOwner,
    timerInfo: state.timerInfo,
  }))

  const userInfo = useAuthStore((state) => state.userInfo)

  useEffect(() => {
    setIsPlaying(isRunning)
  }, [isRunning])

  function onChangeDuration(value) {
    setDuration(value)
  }

  function onSwitchTimer() {
    const roomId = roomInfo.id
    socket.emit('vote:timer', { roomId, action: 'SWITCH', duration }, () =>
      setIsPlaying(!isPlaying)
    )
  }

  function onStopTimer() {
    const roomId = roomInfo.id
    socket.emit('vote:timer', { roomId, action: 'STOP' }, () =>
      setIsPlaying(false)
    )
  }

  if (!isRoomOwner(userInfo.id)) {
    return <></>
  }

  return (
    <Space>
      <Input.Group>
        <Select defaultValue={duration} onChange={onChangeDuration}>
          {timerValues.map((value) => (
            <Select.Option key={'option-' + value} value={value}>
              {value}
            </Select.Option>
          ))}
        </Select>
      </Input.Group>

      <Button.Group>
        <Button
          onClick={onSwitchTimer}
          icon={isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />}
          className="text-green-600"
        />

        <Button
          onClick={onStopTimer}
          icon={<PoweroffOutlined />}
          className="text-red-600"
        />
      </Button.Group>
    </Space>
  )
}
