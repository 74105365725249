import { PlusSquareOutlined } from '@ant-design/icons'
import { Button, Empty } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export const RoomEmptyComponent = () => {
  return (
    <Empty
      imageStyle={{
        height: 60,
      }}
      description={<span>No data</span>}
      className="my-10"
    >
      <Link to="create-room">
        <Button type="primary" icon={<PlusSquareOutlined />}>
          Create a new room
        </Button>
      </Link>
    </Empty>
  )
}
