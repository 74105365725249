import { Col, Modal, Row, message } from 'antd'
import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard/src'

import { useRoomStore } from '../../../state-stores/room.store'

import { QrCodeComponent } from './qr-code.component'

export const QrcodeBoxComponent: React.FC = () => {
  const { isOpenQR, setIsOpenQR, roomInfo } = useRoomStore((state) => ({
    isOpenQR: state.isOpenQR,
    setIsOpenQR: state.setIsOpenQR,
    roomInfo: state.roomInfo,
  }))

  const url = window.location.href

  function onCopy() {
    message.info('Copied to clipboard').then()
  }

  return (
    <Modal
      title={`${roomInfo.name} (#${roomInfo.slug})`}
      centered={true}
      open={isOpenQR}
      onOk={() => setIsOpenQR(false)}
      onCancel={() => setIsOpenQR(false)}
      footer={null}
    >
      <Row justify={'center'}>
        <Col>
          <QrCodeComponent value={url} />
          <CopyToClipboard text={url} onCopy={onCopy}>
            <p className="truncate">{url}</p>
          </CopyToClipboard>
        </Col>
      </Row>
    </Modal>
  )
}
