import {
  EditOutlined,
  HomeOutlined,
  LogoutOutlined,
  PlusSquareOutlined,
  QrcodeOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { MenuProps } from 'antd'
import { Content } from 'antd/es/layout/layout'
import React from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'

import { useAuthStore } from '../state-stores/auth.store'
import { useRoomStore } from '../state-stores/room.store'

function GetMenuItems({ isLoggedIn, userInfo, roomInfo, hasRoomInfo }) {
  const createRoomItem = {
    label: <Link to="create-room">Create a new room</Link>,
    key: 'create-room',
    icon: <PlusSquareOutlined />,
  }
  const editRoomItem = {
    label: 'Edit current room',
    key: 'edit-room',
    icon: <EditOutlined />,
    disabled: !hasRoomInfo || userInfo.id !== roomInfo['owner'],
  }

  const items: MenuProps['items'] = [
    {
      label: <Link to="/">Home</Link>,
      key: 'home',
      icon: <HomeOutlined />,
    },
    {
      label: 'QR code',
      key: 'roomQR',
      icon: <QrcodeOutlined />,
      hidden: !hasRoomInfo,
    },
    { ...createRoomItem, hidden: hasRoomInfo },
    {
      label: 'Room actions',
      key: 'room-actions',
      icon: <QrcodeOutlined />,
      hidden: !hasRoomInfo,
      children: [createRoomItem, editRoomItem],
    },
    {
      label: userInfo.email || 'User',
      key: 'user',
      icon: <UserOutlined />,
      disabled: !isLoggedIn,
      children: [
        {
          label: 'Profile',
          key: 'profile',
          icon: <UserOutlined />,
          disabled: true,
        },
        {
          label: 'Sign out',
          key: 'signOut',
          icon: <LogoutOutlined />,
          danger: true,
        },
      ],
    },
  ]

  return items.filter(({ hidden }) => !hidden)
}

export const MainLayout: React.FC = () => {
  const navigate = useNavigate()

  const { isLoggedIn, userInfo, clearToken } = useAuthStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    userInfo: state.userInfo,
    clearToken: state.clearToken,
  }))

  const { roomInfo, hasRoomInfo, setIsOpenQR, setIsEditing } = useRoomStore(
    (state) => ({
      roomInfo: state.roomInfo,
      hasRoomInfo: state.hasRoomInfo,
      setIsOpenQR: state.setIsOpenQR,
      setIsEditing: state.setIsEditing,
    })
  )

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case 'roomQR':
        setIsOpenQR(true)
        break

      case 'edit-room':
        setIsEditing(true)
        break

      case 'signOut':
        clearToken()
        return navigate('')
    }
  }

  const items = GetMenuItems({
    isLoggedIn,
    userInfo,
    hasRoomInfo,
    roomInfo,
  })

  return (
    <div className="md:container md:mx-auto max-w-4xl">
      <Menu
        onClick={onClick}
        mode="horizontal"
        items={items}
        className="mb-2 text-violet-600 font-semibold"
      />
      <Content>
        <Outlet />
      </Content>
    </div>
  )
}
