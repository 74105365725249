import { useEffect } from 'react'
import socketIOClient from 'socket.io-client'
import { create } from 'zustand'

import { useRoomStore } from './room.store'

export const useWebsocketStore = create((set) => ({
  isConnected: false,
  setConnected: (isConnected) => set(() => ({ isConnected })),
}))

export const socket = socketIOClient(process.env.REACT_APP_SERVER_URL, {
  auth: {
    token: '',
  },
  transport: ['websocket'],
  autoConnect: false,
})

export function ConnectWebSocket() {
  socket.auth.token = localStorage.getItem('token')
  socket.connect()
}

export function DisconnectWebSocket() {
  socket.auth.token = ''
  socket.disconnect()
}

export function Websocket() {
  const {
    setRoomInfo,
    clearRoomInfo,
    setTimerInfo,
    setResults,
    setVoteResult,
  } = useRoomStore((state) => ({
    setRoomInfo: state.setRoomInfo,
    clearRoomInfo: state.clearRoomInfo,
    setTimerInfo: state.setTimerInfo,
    setResults: state.setResults,
    setVoteResult: state.setVoteResult,
  }))

  const setConnected = useWebsocketStore((state) => state.setConnected)

  useEffect(() => {
    socket.on('room:info', ({ data }) => {
      if (data) {
        setRoomInfo(data)
      } else {
        clearRoomInfo()
      }
    })

    socket.on('voteResults', ({ data }) => {
      setResults(data)
    })

    socket.on('vote:result', ({ data }) => {
      setVoteResult(data)
    })

    socket.on('timer:onTime', ({ data }) => {
      setTimerInfo(data)
    })

    socket.on('connect', () => {
      console.log('WS:connected')
      setConnected(true)
    })

    socket.on('disconnect', () => {
      console.log('WS:disconnected')
      setConnected(false)
    })

    socket.on('pong', () => {
      console.log('WS:pong')
    })

    return () => {
      console.log('Websocket:cleanup')
      socket.off('connect')
      socket.off('disconnect')
      socket.off('pong')
    }
  }, [])
}
