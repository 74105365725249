import { SendOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Radio } from 'antd'
import React, { useState } from 'react'

const SettingValues = [
  {
    label: 'Allow others to reset results',
    value: 'allowOthersResetResults',
  },
  {
    label: 'Allow others to show results',
    value: 'allowOthersShowResults',
  },
  {
    label: `Hide the owner voting`,
    value: 'hideOwnerVoting',
  },
]

export const RoomFormComponent: React.FC = ({
  form,
  data = {},
  onChange,
  onFinish,
}) => {
  const [settingValues, setSettingValues] = useState([])

  function onUpdateSettings(values) {
    setSettingValues(values)
  }

  function onFinishForm(updateDate) {
    const settings = settingValues.reduce((map, value) => {
      map[value] = true
      return map
    }, {})
    onFinish({ ...updateDate, settings })
  }

  const currentSettings = SettingValues.map(({ value }) => value).filter(
    (value) => !!data.settings?.[value]
  )

  return (
    <Form
      form={form}
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      size="large"
      onValuesChange={onChange}
      onFinish={onFinishForm}
      autoComplete="off"
      className="m-3"
    >
      <Form.Item
        label="Name"
        name="name"
        initialValue={data.name}
        rules={[
          {
            required: true,
            message: 'Please input room name!',
          },
          { min: 5, message: 'Room name must be minimum 5 characters.' },
          { max: 100, message: 'Room name must be maximum 100 characters.' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Slug"
        name="slug"
        initialValue={data.slug}
        rules={[
          {
            required: true,
            message: 'Please input room slug!',
          },
          { min: 5, message: 'Room slug must be minimum 5 characters.' },
          { max: 100, message: 'Room slug must be maximum 100 characters.' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Privacy"
        name="privacy"
        initialValue={data.privacy || 'OPEN'}
        rules={[
          {
            required: true,
            message: 'Please input room privacy!',
          },
        ]}
      >
        <Radio.Group>
          <Radio value="OPEN" checked="checked">
            Open
          </Radio>
          <Radio value="CLOSED">Closed</Radio>
          <Radio value="PRIVATE" disabled={true}>
            Private
          </Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Settings">
        <Checkbox.Group
          options={SettingValues}
          defaultValue={currentSettings}
          onChange={onUpdateSettings}
          className="groupSettings grid row-start-1	m-0	"
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8 }}>
        <Button type="primary" htmlType="submit" icon={<SendOutlined />}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
