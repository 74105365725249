import { QRCode } from 'antd'
import React from 'react'

interface IQrCode {
  value: string;
  size?: number;
  icon: string;
}

const MAX_ICON_SIZE = 128

export const QrCodeComponent: React.FC<IQrCode> = ({
  value,
  size,
  icon,
}: IQrCode) => {
  const qrSize = size || global.window.innerWidth > 500 ? 360 : 300
  const iconComputedSize = Math.round(qrSize / 5)
  const iconSize =
    iconComputedSize > MAX_ICON_SIZE ? MAX_ICON_SIZE : iconComputedSize

  return (
    <QRCode
      errorLevel="H"
      value={value}
      icon={icon}
      size={qrSize}
      iconSize={iconSize}
    />
  )
}

QrCodeComponent.defaultProps = {
  icon: 'https://www.beincom.com/favicons/apple-touch-icon.webp',
}
