import { create } from 'zustand'

import { ConnectWebSocket, DisconnectWebSocket } from './websocket.store'

const initialState = {
  isLoggedIn: null,
  userInfo: {},
}

export const useAuthStore = create((set) => ({
  ...initialState,
  getToken: () => localStorage.getItem('token'),
  setUserData: (userInfo) => set(() => ({ userInfo })),
  setToken: (token) =>
    set(() => {
      if (token) {
        localStorage.setItem('token', token)
        ConnectWebSocket()

        return { isLoggedIn: true }
      } else {
        return { isLoggedIn: false, userInfo: {} }
      }
    }),
  clearToken: () =>
    set(() => {
      localStorage.removeItem('token')
      DisconnectWebSocket()

      return { isLoggedIn: false, userInfo: {} }
    }),
}))
