import { QuestionCircleOutlined } from '@ant-design/icons'
import { Col, Row, Statistic, Tag } from 'antd'
import React, { useEffect, useState } from 'react'

import { useRoomStore } from '../../../state-stores/room.store'
import { VOTING_TEXTS } from '../vote-values.const'

export const ResultStatComponent: React.FC = () => {
  const {
    results: { isShowing, statistic },
    timerInfo,
  } = useRoomStore((state) => ({
    results: state.results,
    timerInfo: state.timerInfo,
  }))

  const { lowestPoint, highestPoint, averagePoint, mostSelected } =
    statistic || {}

  const [time, setTime] = useState(undefined)

  useEffect(() => {
    const { duration, elapsedTime } = timerInfo
    if (duration) {
      const remaining = Math.round(duration - elapsedTime)
      setTime(remaining >= 10 ? remaining : '0' + remaining)
    }
  }, [timerInfo])

  const statFormatter = ({ color }) => {
    return (value) => {
      if (!isShowing) {
        return (
          <Tag className="text-2xl w-12 text-center">
            <QuestionCircleOutlined />
          </Tag>
        )
      }

      return (
        <Tag color={color} className="text-2xl w-12 text-center">
          {VOTING_TEXTS[value] || value}
        </Tag>
      )
    }
  }

  return (
    <>
      <Row className="flex-center gap-x-2">
        <Col span={{ xs: 8, lg: 4 }} className="flex-center">
          <Statistic
            title="Timer"
            value={time}
            formatter={(value) => {
              return typeof value !== undefined ? (
                <Tag
                  color={'#6042EC'}
                  className="text-2xl w-12 roportional-nums text-center"
                >
                  {value}
                </Tag>
              ) : undefined
            }}
            className="flex-col flex-center"
          />
        </Col>

        <Col span={{ xs: 4, lg: 4 }}>
          <Statistic
            title="Min"
            value={typeof lowestPoint === 'number' ? lowestPoint : ''}
            formatter={statFormatter({ color: 'rgb(30, 150, 30' })}
            className="flex-col flex-center"
          />
        </Col>

        <Col span={{ xs: 4, lg: 4 }}>
          <Statistic
            title="Max"
            value={typeof highestPoint === 'number' ? highestPoint : ''}
            formatter={statFormatter({ color: 'rgb(222, 50, 80)' })}
            className="flex-col flex-center"
          />
        </Col>

        <Col span={{ xs: 4, lg: 4 }}>
          <Statistic
            title="Average"
            value={typeof averagePoint === 'number' ? averagePoint : ''}
            formatter={statFormatter({ color: 'rgb(20, 120, 255)' })}
            className="flex-col flex-center"
          />
        </Col>

        <Col span={{ xs: 4, lg: 6 }}>
          <Statistic
            title="Most selected"
            value={mostSelected ? mostSelected[0] : ''}
            formatter={statFormatter({ color: '#6042EC' })}
            className="flex-col flex-center"
          />
        </Col>
      </Row>
    </>
  )
}
