import { Badge, Button } from 'antd'
import React, { useEffect, useState } from 'react'

import { useAuthStore } from '../../state-stores/auth.store'
import { useRoomStore } from '../../state-stores/room.store'
import { socket } from '../../state-stores/websocket.store'

import { VOTING_TEXTS } from './vote-values.const'

const BUTTONS = [
  {
    value: 0,
    text: VOTING_TEXTS['0'],
  },
  {
    value: 0.5,
    text: VOTING_TEXTS['0.5'],
  },
  {
    value: 1,
  },
  {
    value: 2,
  },
  {
    value: 3,
  },
  {
    value: 5,
  },
  {
    value: 8,
    high: true,
  },
  {
    value: 13,
    high: true,
  },
  {
    value: 20,
    high: true,
  },
]

export const VoteButtonsComponent: React.FC = () => {
  const [selected, setSelected] = useState(null)
  const [pointLoading, setPointLoading] = useState(null)

  const userInfo = useAuthStore((state) => state.userInfo)

  const {
    results: {
      estimators = [],
      statistic: { countByPoints = {} } = {},
      isLocking = false,
    },
    isWatcher,
    voteResult: { point: currentSelected } = {},
    hasVoted,
    roomInfo,
    isRoomOwner,
  } = useRoomStore((state) => ({
    results: state.results,
    isWatcher: state.isWatcher,
    voteResult: state.voteResult,
    hasVoted: state.hasVoted,
    roomInfo: state.roomInfo,
    isRoomOwner: state.isRoomOwner,
  }))

  useEffect(() => {
    if (selected === null) {
      setSelected(currentSelected)
    }
  }, [currentSelected])

  useEffect(() => {
    if (!hasVoted(userInfo.id)) {
      setSelected(null)
    }
  }, [estimators])

  function onSelected(valueSelected) {
    if (valueSelected === selected || isWatcher(userInfo.id)) {
      return
    }

    setPointLoading(valueSelected)
    const roomId = roomInfo.id
    socket.emit('vote', { roomId, point: valueSelected }, ({ isOk }) => {
      setPointLoading(null)
      if (isOk) {
        setSelected(valueSelected)
      }
    })
  }

  const isOwner = isRoomOwner(userInfo.id)

  return (
    <>
      <div className="flex justify-center flex-wrap max-w gap-x-2.5 gap-y-3">
        {BUTTONS.map(({ value, text, high }) => {
          const isHideOwnerVoting = !!roomInfo?.settings?.['hideOwnerVoting']
          const isMarkAsSelected =
            value === selected && (!isOwner || !isHideOwnerVoting)
          const type = isMarkAsSelected ? 'primary' : 'default'
          const danger = high ? 'danger' : undefined
          const loading = pointLoading === value
          const disabled = loading || (isLocking && !isOwner)
          const props = { type, danger, disabled }

          return (
            <Badge
              key={value}
              color="#6042EC"
              count={countByPoints[value]}
              offset={[-5, 0]}
            >
              <Button
                size="large"
                onClick={() => onSelected(value)}
                {...props}
                className="h-[64px] w-[64px] font-medium text-2xl"
              >
                {text || value}
              </Button>
            </Badge>
          )
        })}
      </div>
    </>
  )
}
