import { Drawer, Form } from 'antd'
import axios from 'axios'
import React from 'react'

import { useRoomStore } from '../../state-stores/room.store'

import { RoomFormComponent } from './room-form.component'

export const RoomEditingComponent: React.FC = ({ data }) => {
  const [form] = Form.useForm()

  const { isEditing, setIsEditing } = useRoomStore((state) => ({
    isEditing: state.isEditing,
    setIsEditing: state.setIsEditing,
  }))

  function onFinish(formData) {
    axios
      .put('/rooms/' + data.id, formData)
      .then(() => {
        setIsEditing(false)
      })
      .catch(console.error)
  }

  return (
    <>
      <Drawer
        title="Edit room info"
        placement="right"
        onClose={() => setIsEditing(false)}
        open={isEditing}
        width={global.window.innerWidth >= 736 ? 736 : global.window.innerWidth}
      >
        <RoomFormComponent
          form={form}
          data={data}
          onChange={() => {}}
          onFinish={onFinish}
        />
      </Drawer>
    </>
  )
}
