import { HomeOutlined } from '@ant-design/icons'
import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

export const NotFoundPage: React.FC = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <Button type="primary" icon={<HomeOutlined />}>
            Back to home
          </Button>
        </Link>
      }
    />
  )
}
