import { MonitorOutlined } from '@ant-design/icons'
import { Button, Divider, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useAuthStore } from '../../state-stores/auth.store'
import { useRoomStore } from '../../state-stores/room.store'
import { socket, useWebsocketStore } from '../../state-stores/websocket.store'
import { NotFoundPage } from '../not-found.page'

import { ListResultsComponent } from './list-results.component'
import { ListWatcherComponent } from './list-watcher.component'
import { QrcodeBoxComponent } from './room-actions/qrcode-box.component'
import { ResultActionsComponent } from './room-actions/result-actions.component'
import { ResultStatComponent } from './room-actions/result-stat.component'
import { ResultTimerActionsComponent } from './room-actions/result-timer-actions.component'
import { RoomEditingComponent } from './room-editing.component'
import { VoteButtonsComponent } from './vote-buttons.component'

export const RoomPage: React.FC = () => {
  const { roomSlug } = useParams()
  const navigate = useNavigate()
  const [isLoaded, setLoaded] = useState(false)

  const userInfo = useAuthStore((state) => state.userInfo)

  const {
    isWatcher,
    roomInfo,
    hasRoomInfo,
    setRoomInfo,
    getRoomInfo,
    clearRoomInfo,
  } = useRoomStore((state) => ({
    results: state.results,
    isWatcher: state.isWatcher,
    roomInfo: state.roomInfo,
    hasRoomInfo: state.hasRoomInfo,
    setRoomInfo: state.setRoomInfo,
    getRoomInfo: state.getRoomInfo,
    clearRoomInfo: state.clearRoomInfo,
  }))

  const isConnected = useWebsocketStore((state) => state.isConnected)

  function isInWatcherMode() {
    return isWatcher(userInfo.id)
  }

  function changeWatcherMode() {
    const isWatcherMode = isInWatcherMode()
    socket.emit(
      'vote:watcher',
      { roomId: roomInfo.id, isWatcher: !isWatcherMode },
      () => {}
    )
  }

  useEffect(() => {
    if (isConnected) {
      socket.emit('room:join', { roomSlug }, ({ isOk, room }) => {
        if (isOk) {
          setRoomInfo(room)
        } else {
          clearRoomInfo()
        }
        setLoaded(true)
      })
    }
  }, [isConnected, roomSlug])

  useEffect(() => {
    const newSlug = roomInfo.slug
    if (newSlug && newSlug !== roomSlug) {
      navigate(`/room/${newSlug}`)
    }
  }, [roomInfo])

  useEffect(() => {
    return () => {
      const currentRoomInfo = getRoomInfo()
      const roomId = currentRoomInfo.id
      if (roomId) {
        socket.emit('room:leave', { roomId }, () => {})
      }
      clearRoomInfo()
    }
  }, [])

  if (!isLoaded) {
    return
  }

  if (!hasRoomInfo) {
    return <NotFoundPage />
  }

  return (
    <>
      <RoomEditingComponent data={roomInfo} />
      <QrcodeBoxComponent />

      <Tooltip title="Turn on/off watch only" placement="leftTop">
        <Button
          icon={<MonitorOutlined />}
          className="float-right mr-1"
          onClick={changeWatcherMode}
          type={isInWatcherMode() ? 'primary' : 'default'}
        />
      </Tooltip>

      <div className="flex-center flex-col gap-1 my-3">
        <div className="text-gray-50">Scrum Story Points</div>
        <div className="text-2xl font-medium text-violet-600">
          {roomInfo.name}
        </div>
      </div>

      {/* Actions */}
      <div className="flex flex-center flex-col gap-2 mt-2">
        <div className="flex flex-center gap-x-12 gap-y-2 flex-wrap">
          <ResultTimerActionsComponent />
          <ResultActionsComponent />
        </div>
        <VoteButtonsComponent />
      </div>

      {/* Stats */}
      <Divider>Stats</Divider>
      <ResultStatComponent />

      {/* Result */}
      <Divider>Result</Divider>
      <ListWatcherComponent />
      <ListResultsComponent />
    </>
  )
}
