import { Avatar, Tooltip } from 'antd'
import React from 'react'

import { useRoomStore } from '../../state-stores/room.store'

export const ListWatcherComponent: React.FC = () => {
  const results = useRoomStore((state) => state.results)

  const { watchers = [] } = results || {}

  if (watchers.length === 0) {
    return
  }

  return (
    <>
      <div className="flex my-1">
        <label className="text-sm mx-1">Watchers</label>
        <Avatar.Group>
          {watchers.map(({ id, name, email, avatar }) => (
            <Tooltip key={id} title={name + ' (' + email + ')'} placement="top">
              <Avatar size="small" src={avatar} />
            </Tooltip>
          ))}
        </Avatar.Group>
      </div>
    </>
  )
}
