import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  GlobalOutlined,
  LockOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Avatar, Button, List, Popconfirm, Skeleton, Spin } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useAuthStore } from '../../state-stores/auth.store'

import { RoomEmptyComponent } from './room-empty.component'

async function getListRoom() {
  try {
    const { data } = await axios.get('/rooms')

    const sortedRooms = (data.docs || [])
      .sort((a, b) => {
        if (a.name === b.name) {
          return 0
        }
        return a.name > b.name ? 1 : -1
      })
      .sort((a, b) => Number(b['isOwner']) - Number(a['isOwner']))

    return { docs: sortedRooms }
  } catch (e) {
    return {
      docs: [],
    }
  }
}

const DeleteRoomButton: React.FC = ({ data, onConfirm }) => {
  if (!data['isOwner']) {
    return
  }

  return (
    <Popconfirm
      placement="leftTop"
      title="Delete room"
      description={`Are you sure to DELETE [${data.name}]? You CAN'T UNDO this action!`}
      onConfirm={() => onConfirm(data.id)}
      okType="danger"
      icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
      okText="Yes"
      cancelText="No"
    >
      <Button type="default" icon={<DeleteOutlined />} danger={true} />
    </Popconfirm>
  )
}

const RoomTitle = (room) => {
  const privacyIcons = {
    OPEN: <GlobalOutlined className="text-violet-600" />,
    CLOSED: <EyeInvisibleOutlined className="text-zinc-500" />,
    PRIVATE: <LockOutlined className="text-neutral-700" />,
  }

  return (
    <>
      {privacyIcons[room.privacy]}{' '}
      <Link to={'room/' + room.slug}>{room.name}</Link>
    </>
  )
}

const RoomDescription = (room) => {
  return (
    <>
      Managed by <Avatar size={20} src={room['ownerInfo'].avatar} />{' '}
      {room['ownerInfo'].name}
      {room['isOwner'] ? ' (you)' : ''}
    </>
  )
}

export const ListRooms: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState([])

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn)

  const [updateState, setUpdateState] = React.useState()
  const forceUpdate = React.useCallback(() => setUpdateState({}), [])

  useEffect(() => {
    if (isLoggedIn) {
      setIsLoading(true)
      getListRoom()
        .then(({ docs }) => setData(docs))
        .catch(() => setData([]))
        .finally(() => {
          setIsLoading(false)
        })
    } else {
      setData([])
    }
  }, [isLoggedIn, updateState])

  if (isLoading) {
    return <Skeleton active />
  } else if (data.length === 0) {
    return <RoomEmptyComponent />
  }

  function deleteRoom(id: string) {
    axios.delete(`/rooms/${id}`).finally(() => {
      forceUpdate()
    })
  }

  return (
    <Spin tip="Loading..." spinning={isLoading}>
      <List
        className="mx-3"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(room) => {
          return (
            <List.Item>
              <Skeleton loading={isLoading} active avatar>
                <List.Item.Meta
                  title={RoomTitle(room)}
                  description={RoomDescription(room)}
                />
                <DeleteRoomButton data={room} onConfirm={deleteRoom} />
              </Skeleton>
            </List.Item>
          )
        }}
      />
    </Spin>
  )
}
