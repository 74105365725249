import { SmileOutlined } from '@ant-design/icons'
import { Result, Spin, message } from 'antd'
import axios from 'axios'
import React, { useEffect } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'

import './styles/global.css'
import { MainLayout } from './layouts/main.layout'
import { GoogleLoginButton } from './pages/home/google-login.component'
import { HomePage } from './pages/home/home.page'
import { NotFoundPage } from './pages/not-found.page'
import { RoomPage } from './pages/room/room.page'
import { RoomCreatingPage } from './pages/room-creating.page'
import './utils/axios.util'
import { useAuthStore } from './state-stores/auth.store'
import { Websocket } from './state-stores/websocket.store'

const App: React.FC = () => {
  const { isLoggedIn, getToken, setToken, setUserData } = useAuthStore(
    (state) => ({
      isLoggedIn: state.isLoggedIn,
      getToken: state.getToken,
      setToken: state.setToken,
      setUserData: state.setUserData,
    })
  )

  useEffect(() => {
    const token = getToken()
    if (token) {
      axios
        .get('/authentication')
        .then(({ data: { token, data } }) => {
          setToken(token)
          setUserData(data)
        })
        .catch(({ response: { data } }) => {
          if (data.statusCode === 403) {
            message.error(data.error).then()
            setToken('')
          }
        })
    } else {
      setToken('')
    }
  }, [])

  if (isLoggedIn === null) {
    return (
      <Spin spinning={true}>
        <Result icon={<SmileOutlined />} title="Wait a bit!" />
      </Spin>
    )
  } else if (!isLoggedIn) {
    return (
      <Result
        status="403"
        title="Oops"
        subTitle="You must log in to continue."
        extra={<GoogleLoginButton />}
      />
    )
  }

  return (
    <>
      <Websocket />

      <HashRouter>
        <Routes>
          <Route path="" element={<MainLayout />}>
            <Route index element={<HomePage />} />
            <Route path="create-room" element={<RoomCreatingPage />}></Route>
            <Route path="room/:roomSlug" element={<RoomPage />}></Route>
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </HashRouter>
    </>
  )
}

export default App
