import {
  ClearOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  LockOutlined,
  ReloadOutlined,
  UnlockOutlined,
} from '@ant-design/icons'
import { Button, Popconfirm, Space, Tooltip } from 'antd'
import React from 'react'

import { useAuthStore } from '../../../state-stores/auth.store'
import { useRoomStore } from '../../../state-stores/room.store'
import { socket } from '../../../state-stores/websocket.store'

export const ResultActionsComponent: React.FC = () => {
  const userInfo = useAuthStore((state) => state.userInfo)

  const { results, roomInfo, isRoomOwner } = useRoomStore((state) => ({
    results: state.results,
    roomInfo: state.roomInfo,
    isRoomOwner: state.isRoomOwner,
  }))

  const {
    estimators = [],
    watchers = [],
    isShowing = false,
    isLocking = false,
  } = results || {}

  function onHandleLockResult() {
    const roomId = roomInfo.id
    socket.emit(`result:action`, {
      roomId,
      action: 'LOCK',
      enabled: !isLocking,
    })
  }

  function onAction(action) {
    const roomId = roomInfo.id
    socket.emit(`result:action`, { roomId, action })
  }

  function hasSettingPermission(settingPermission) {
    const hasPermission = roomInfo?.settings?.[settingPermission]

    return isRoomOwner(userInfo.id) || hasPermission
  }

  const hasDisconnectedEstimator = !![...estimators, ...watchers].find(
    ({ isOnline }) => !isOnline
  )
  const hasAtLeastOneVoted = !!estimators.find(({ isVoted }) => isVoted)

  const isAllowLockResult = hasSettingPermission('*allowLockResults')

  const isAllowOthersDeleteResults =
    hasSettingPermission('allowOthersResetResults') && hasDisconnectedEstimator
  const isAllowOthersResetResults =
    hasSettingPermission('allowOthersResetResults') && hasAtLeastOneVoted
  const isAllowOthersShowResults = hasSettingPermission(
    'allowOthersShowResults'
  )

  if (
    !isAllowLockResult &&
    !isAllowOthersResetResults &&
    !isAllowOthersShowResults
  ) {
    return <></>
  }

  return (
    <Space size="small">
      <Tooltip
        placement="bottom"
        title="Lock/Unlock the voting buttons"
        color={'#6042EC'}
      >
        <Button
          {...{ type: isLocking ? 'primary' : 'default', danger: isLocking }}
          onClick={onHandleLockResult}
          icon={isLocking ? <LockOutlined /> : <UnlockOutlined />}
          disabled={!isAllowLockResult}
        />
      </Tooltip>

      <Popconfirm
        title="Clear list results"
        description="Are you sure to clear list? Just remove all disconnected member in this room!"
        onConfirm={() => onAction('DELETE')}
        okText="Yes"
        cancelText="No"
        placement="bottom"
        disabled={!isAllowOthersDeleteResults}
      >
        <Tooltip
          placement="bottom"
          title="Remove all disconnected member in this room"
          color={'#6042EC'}
        >
          <Button
            type="default"
            icon={<ClearOutlined />}
            danger
            disabled={!isAllowOthersDeleteResults}
          />
        </Tooltip>
      </Popconfirm>

      <Popconfirm
        title="Reset point results"
        description="Are you sure to reset point?"
        onConfirm={() => onAction('RESET')}
        okText="Yes"
        cancelText="No"
        placement="bottom"
        disabled={!isAllowOthersResetResults}
      >
        <Button icon={<ReloadOutlined />} disabled={!isAllowOthersResetResults}>
          Reset
        </Button>
      </Popconfirm>

      <Button
        icon={isShowing ? <EyeOutlined /> : <EyeInvisibleOutlined />}
        onClick={() => onAction('SHOW')}
        disabled={!isAllowOthersShowResults}
        className="ml-5 text-violet-600"
      >
        Show results
      </Button>
    </Space>
  )
}
