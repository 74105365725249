import { Form, Divider, message } from 'antd'
import axios from 'axios'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { convertToSlug } from '../utils/string.util'

import { RoomFormComponent } from './room/room-form.component'

interface IRoomSettings {}

interface IRoomData {
  name: string;
  slug: string;
  privacy: string;
  settings: IRoomSettings;
}

export const RoomCreatingPage: React.FC = ({ data }: { data: IRoomData }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const onChange = (data) => {
    if (data.name) {
      form.setFieldsValue({
        slug: convertToSlug(data.name),
      })
    }
  }

  function onFinish(data) {
    axios
      .post('/rooms', data)
      .then((res) => {
        return navigate(`/room/${res.data.slug}`)
      })
      .catch(({ response: { data } }) => {
        const errorMessage =
          data.message || 'Something went wrong when creating a new room'

        message.error(`${errorMessage}. Please try again!`).then()
      })
  }

  return (
    <>
      <Divider>Create a new room</Divider>
      <RoomFormComponent
        form={form}
        data={data}
        onChange={onChange}
        onFinish={onFinish}
      />
    </>
  )
}

RoomCreatingPage.defaultProps = {
  data: {},
}
