import axios from 'axios'

axios.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.REACT_APP_SERVER_URL

    const token = localStorage.getItem('token')
    if (token) {
      config.headers = {
        authorization: `Bearer ${token}`,
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)
